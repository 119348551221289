
import { defineComponent } from 'vue'

import LoginForm from './form.vue'

export default defineComponent({
  name: 'AppLogin',
  components: {
    LoginForm
  }
})
